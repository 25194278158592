<template>
  <div>
    <main-header />

  <!-- Content -->
  <section>
      <!-- Breadcrumb -->
    <div class="container">
      <div class="_flex _center">
        <nav aria-label="breadcrumb" style="height:40px!important">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="#">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              À propos
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <!--  -->
    <div class="container">
      <div class="px-5-md px-8-lg my-10">
        <div class="text-center">
          <h2><b>À propos de nous</b></h2>
          <div style="height:500px"></div>
        </div>
      </div>
    </div>
    <!--  -->
  </section>
  <!--  -->

  <main-footer />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
